import * as klaro from 'klaro'
import consts from '@/consts'

export default defineNuxtPlugin((_nuxtApp) => {
  const gtm = useGtm()
  const GRACE_PERIOD_BEFORE_LOADING_GTM_MS = 500

  function enableTracking(hasConsented, service) {
    setTimeout(() => {
      if (hasConsented && !service.required) {
        /**
         * The user has at least consented to one non-required service, so we enable GTM.
         * In the GTM dashboard we parse the Klaro cookie to enable the correct services individually.
         */
        gtm.enable()
      }
    }, GRACE_PERIOD_BEFORE_LOADING_GTM_MS)
  }

  const config = {
    elementID: 'KLARO_COOKIE_NOTICE',
    cookieName: 'klaro',
    cookieExpiresAfterDays: 120,
    mustConsent: false,
    additionalClass: 'beqom-klaro',
    privacyPolicy: '/privacy',
    acceptAll: true,
    translations: {
      en: {
        consentNotice: {
          description:
            'This website uses cookies for {purposes} that are used to improve our website and provide more personalized services to you, both on this website and through other media. We will not track your information when you visit our site, but in order to comply with your preferences, we will have to set one cookie to store your preferences.',
        },
        ok: 'Accept all',
        purposes: {
          advertisement: 'Advertisement',
          analytics: 'Analytics',
          functionality: 'Functionality',
          necessary: 'Necessary',
        },
      },
      fr: {
        purposes: {
          advertisement: 'Cookies publicitaires',
          analytics: "Cookies d'analyse",
          functionality: 'Cookies fonctionnels',
          necessary: 'Cookies nécessaires',
        },
      },
      de: {
        purposes: {
          advertisement: 'Werbe-Cookies',
          analytics: 'Analytics-Cookies',
          functionality: 'Funktionelle Cookies',
          necessary: 'Notwendige Cookies',
        },
      },
    },
    services: [
      {
        name: 'googleAnalytics',
        title: 'Google Analytics',
        purposes: ['analytics'],
        cookies: [/^_ga/, /^_gid/],
        required: false,
        callback: function (consent, service) {
          enableTracking(consent, service)
        },
      },
      {
        name: 'googleAds',
        title: 'Google Ads',
        purposes: ['advertisement'],
        cookies: [], // TODO
        required: false,
        callback: function (consent, service) {
          enableTracking(consent, service)
        },
      },
      {
        name: 'gartnerDigitalMarkets',
        title: 'Gartner Digital Markets',
        purposes: ['advertisement'],
        cookies: [], // TODO
        required: false,
        callback: function (consent, service) {
          enableTracking(consent, service)
        },
      },
      {
        name: 'linkedin',
        title: 'LinkedIn',
        purposes: ['analytics', 'advertisement'],
        cookies: [
          'bcookie',
          'lidc',
          'bscookie',
          'JSESSIONID',
          'lang',
          'lidc',
          'sdsc',
          'li_gc',
          'li_mc',
          'UID',
          'UserMatchHistory',
          'AnalyticsSyncHistory',
          'lms_ads',
          'lms_analytics',
          'li_fat_id',
          'li_sugr',
          '_guid',
          'BizographicsOptOut',
          'li_giant',
          'oribi_cookie_test',
          'oribili_user_guid',
          'ln_or',
          'ar_debug',
          'aam_uuid',
          '_gcl_au',
          '_gcl_aw',
          '_gcl_dc',
          'brwsr',
          'ABSELB',
          'IRLD',
          'l_page',
          '_rdt_uuid',
        ],
        required: false,
        callback: function (consent, service) {
          enableTracking(consent, service)
        },
      },
      {
        name: 'hubspotMarketing',
        title: 'HubSpot Marketing',
        purposes: ['analytics', 'advertisement'],
        cookies: ['_cfuvid', '_cfuvid', '__hssc', '__hssrc', 'hubspotutk', '__hstc'],
        required: false,
        callback: function (consent, service) {
          enableTracking(consent, service)
        },
      },
      // Essential cookies.
      {
        name: 'hubspotEssential',
        title: 'Cloudflare bot protection for HubSpot forms',
        purposes: ['necessary'],
        cookies: ['__cf_bm'],
        required: true,
        callback: function (consent, service) {
          enableTracking(consent, service)
        },
      },
      {
        name: 'klaro',
        title: 'Klaro',
        purposes: ['necessary'],
        cookies: ['klaro'],
        required: true,
        callback: function (consent, service) {
          enableTracking(consent, service)
        },
      },
    ],
  }

  const shouldPageHaveCookieBanner = !consts.pagesWithNoCookieBanner.includes(
    window.location.pathname,
  )

  if (shouldPageHaveCookieBanner) {
    window.klaro = klaro
    klaro.setup(config)
  }
})
