import redirects from './redirects'

type crossoriginOptions = '' | 'anonymous' | 'use-credentials'
type LinkAsType =
  | 'object'
  | 'font'
  | 'style'
  | 'script'
  | 'worker'
  | 'audio'
  | 'document'
  | 'embed'
  | 'fetch'
  | 'image'
  | 'track'
  | 'video'

const allowedCanonicalQueryParams: allowedQueryParamForCanonicalUrls[] = [
  {
    param: 'page',
    path: ['/news', '/resources', '/blog', '/success-stories', '/featured-news'],
  },
  {
    param: 'topic',
    path: ['/resources', '/blog'],
  },
]

const consts = {
  baseUrl: 'https://www.beqom.com',
  websiteUrl: 'https://www.beqom.com/',
  contactUsPage: 'https://www.beqom.com/contact-us',
  defaultOpenGraphImage: 'https://www.beqom.com/beqom-og-default.jpg',
  defaultLogoMark: 'https://www.beqom.com/beqom-default-logomark.png',
  worldMapGraphic: 'https://images.prismic.io/beqom-com/Zs3XW0aF0TcGJbaD_map.jpg',
  phoneSalesNorthAmerica: '+1-646-952-0088',
  phoneSalesGermany: '+49-89-8908-1697',
  phoneSalesFrance: '+33-1-89-54-97-77',
  phoneSalesUnitedKingdom: '+44-20-3668-6837',
  phoneSalesItaly: '+39-02-62-03-2124',
  phoneSalesWorldwide: '+41-58-255-08-00',
  phoneSupportNorthAmerica: '+1-800-986-5681',
  phoneSupportWorldwide: '+41-44-551-43-75',
  baseDomain: 'beqom.com',
  companyName: 'beqom',
  companyLegalName: 'beqom SA',
  hubspotPartnerID: '4022322',
  hubspotformRegion: 'na1',
  hubspotformContainerIdPrefix: 'HS_FORM_', // This is also being targeted in CSS/LESS.
  capterraVkey: 'fcf3148e6594f9b83354451b99aa3d90',
  capterraVid: '2043625',
  defaultArticleGridPageSize: 19,
  pressArticlesPageSize: 18,
  paginationQueryParam: 'page',
  companyDescription:
    'beqom is a comprehensive compensation management software designed for enterprise organizations. It focuses on managing compensation, employee performance, and pay equity.',
  prismicRepoName: 'beqom-com',
  defaultArticleCategoryForStructuredData: 'Human Resources',
  reCaptchaSiteKey: '***',
  defaultLocale: 'en-us',
  fathomTrackingCodeForDotCom: 'ZTZFUMRM',
  favicons: [
    { rel: 'apple-touch-icon', type: 'image/png', sizes: '180x180', href: '/apple-touch-icon.png' },
    { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' },
    { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png' },
    { rel: 'mask-icon', color: '#000000', href: '/safari-pinned-tab.svg' },
    { rel: 'manifest', href: '/site.webmanifest' },
  ],
  preconnects: [
    // Prismic CDN.
    {
      rel: 'preconnect',
      href: 'https://static.cdn.prismic.io/',
      crossorigin: '' as crossoriginOptions,
    },
    {
      rel: 'preconnect',
      href: 'https://beqom-com.cdn.prismic.io/',
      crossorigin: '' as crossoriginOptions,
    },
    {
      rel: 'preconnect',
      href: 'https://images.prismic.io/',
      crossorigin: '' as crossoriginOptions,
    },
    // Vercel scrips.
    {
      rel: 'preconnect',
      href: 'https://va.vercel-scripts.com/',
      crossorigin: '' as crossoriginOptions,
    },
    // Google Tag Manager.
    {
      rel: 'preconnect',
      href: 'https://www.googletagmanager.com/',
      crossorigin: '' as crossoriginOptions,
    },
    // Fathom Analytics.
    {
      rel: 'preconnect',
      href: 'https://cdn.usefathom.com/',
      crossorigin: '' as crossoriginOptions,
    },
    // HS Forms.
    {
      rel: 'preconnect',
      href: 'https://js.hsforms.net/',
      crossorigin: '' as crossoriginOptions,
    },
    // Preload fonts.
    {
      rel: 'preload',
      href: '/fonts/dm-sans-v14-latin-500.woff2',
      as: 'font' as LinkAsType,
      type: 'font/woff2',
      crossorigin: 'anonymous' as crossoriginOptions,
    },
    {
      rel: 'preload',
      href: '/fonts/dm-sans-v14-latin-600.woff2',
      as: 'font' as LinkAsType,
      type: 'font/woff2',
      crossorigin: 'anonymous' as crossoriginOptions,
    },
    {
      rel: 'preload',
      href: '/fonts/dm-sans-v14-latin-regular.woff2',
      as: 'font' as LinkAsType,
      type: 'font/woff2',
      crossorigin: 'anonymous' as crossoriginOptions,
    },
  ],
  socialUrls: {
    twitter: {
      url: 'https://x.com/beqom',
      ariaName: 'Twitter',
      svgIconName: 'TwitterSvg',
    },
    facebook: {
      url: 'https://www.facebook.com/beqom1/',
      ariaName: 'Facebook',
      svgIconName: 'FacebookSvg',
    },
    instagram: {
      url: 'https://www.instagram.com/beqom/',
      ariaName: 'Instagram',
      svgIconName: 'InstagramSvg',
    },
    linkedIn: {
      url: 'https://www.linkedin.com/company/beqom/',
      ariaName: 'LinkedIn',
      svgIconName: 'LinkedInSvg',
    },
    youTube: {
      url: 'https://www.youtube.com/@beqom_compcomplete',
      ariaName: 'YouTube',
      svgIconName: 'YouTubeSvg',
    },
    vimeo: {
      url: null,
      ariaName: 'Vimeo',
      svgIconName: 'VimeoSvg',
    },
    rssNewsfeed: {
      url: null,
      ariaName: 'RSS',
      svgIconName: 'RssSvg',
    },
    sitemap: {
      url: '/sitemap.xml',
      ariaName: 'Sitemap',
      svgIconName: 'SitemapSvg',
    },
  } as SocialUrlObj,
  permanentlyDeletedPages: [] as string[],
  redirects,
  allowedCanonicalQueryParams,
  pagesWithNoCookieBanner: ['/tenant-under-maintenance', '/internal', '/internal/blog'] as string[],
}

export default consts
