export default [
  // Locales.
  ['/de', '/de-de'],
  ['/DE-de', '/de-de'],
  ['/FR-fr', '/fr-fr'],
  ['/de/new-homepage', '/de-de'],
  ['/fr', '/fr-fr'],
  ['/it', '/'],
  ['/en-us', '/'],
  // Other
  ['/cs/c', '/'],
  ['/cs/c/', '/'],
  // Legal.
  ['/functionality-description/12/23', '/functionality-description'],
  ['/functionality-description/11/23', '/functionality-description'],
  ['/functionality-description/09/23', '/functionality-description'],
  ['/functionality-description/07/23', '/functionality-description'],
  ['/functionality-description/01/24', '/functionality-description'],
  ['/functionality-description/12/23-0', '/functionality-description'],
  ['/sla/07/23', '/sla-07-23'],
  ['/sla/02/24', '/sla'],
  ['/legal-faqs/07/23', '/legal-faqs'],
  ['/approved-subprocessors/03/24', '/approved-subprocessors'],
  ['/approved-subprocessors/07/23', '/approved-subprocessors'],
  ['/approved-subprocessors/01/24', '/approved-subprocessors'],
  ['/data-processing-agreement', '/dpa'],
  ['/dpa/07/23', '/dpa'],
  ['/dpa/07/23-0', '/dpa'],
  ['/dpa/01/24', '/dpa'],
  ['/master-software-as-service-agreement', '/msa'],
  ['/msa/07/23', '/msa'],
  ['/msa/01/24', '/msa'],
  ['/pe-legal-definitions/02/24', '/pe-legal-definitions'],
  ['/legal-definitions/07/23', '/legal-definitions'],
  ['/divestment-terms/07/23', '/divestment-terms'],
  ['/sov/ent/11-23', '/csp-ent-11-23'],
  ['/sov/p/11-23', '/csp-prem-11-23'],
  ['/sov/pp/11-23', '/csp-premplus-11-23'],
  ['/sov/sp/11-23', '/csp-stdplus-11-23'],
  ['/csp/ent/11/23', '/csp-ent-11-23'],
  ['/csp/prem/11/23', '/csp-prem-11-23'],
  ['/csp/premplus/11/23', '/csp-premplus-11-23'],
  ['/csp/stdplus/11/23', '/csp-stdplus-11-23'],
  ['/technical-org-measures/02/24', '/technical-org-measures'],
  ['/technical-org-measures/04/23', '/technical-org-measures'],
  ['/pe-ma/02/24', '/pe-ma'],
  ['/pe-sla/02/24', '/pe-sla'],
  ['/pe-functionality-description/02/24', '/functionality-description-pe'],
  // Events.
  ['/hr-tech-2024', '/events/hr-tech-2024'],
  ['/waw-webinar-pay-equity-9-may', '/events/waw-webinar-pay-equity-9-may'],
  ['/sara-2024', '/events/sara-2024'],
  ['/hrcorereward-2024', '/events/hrcorereward-2024'],
  ['/unleash-world-paris-2024', '/events/unleash-world-paris-2024'],
  ['/reba-24', '/events/reba-24'],
  ['/waw-total-rewards-2024', '/events/waw-total-rewards-2024'],
  ['/compensation-leadership-summit-2024', '/events/compensation-leadership-summit-2024'],
  // Blogs
  ['/blog/saas-based-delivery-model', '/blog'],
  ['/blog/new-survey-assesses-workforce-of-the-future', '/blog'],
  ['/blog/roi-of-digital-hr-transformation-infographic', '/blog'],
  ['/blog/face-to-face-with-managing-rewards', '/blog'],
  ['/blog/hr-technology-conference-beqom-takeaways', '/blog'],
  ['/blog/goldman-sachs', '/blog'],
  ['/blog/beqom-labs-nyon', '/blog'],
  ['/blog/office-poland', '/blog'],
  ['/blog/are-your-sales-compensation-plans-like-the-wild-west-try-governance', '/blog'],
  ['/blog/making-employees-happy-quantifying-happiness', '/blog'],
  ['/blog/why-is-compensation-management-so-complex-in-telcos', '/blog'],
  ['/blog/looking-back-at-2016', '/blog'],
  ['/blog/partner-consultancy-si-firms', '/blog'],
  ['/blog/the-top-three-overlooked-lessons-in-hr-digital-transformation', '/blog'],
  ['/blog/partnerships-win-win-solutions', '/blog'],
  ['/blog/executive-compensation-and-benefits-summit-beqom-takeaways', '/blog'],
  ['/blog/new-sma-survey-shows-shifting-trends-in-sales-compensation', '/blog'],
  ['/blog/hr-compensation-process-banks', '/blog'],
  ['/blog/the-new-need-for-agile-total-rewards', '/blog'],
  ['/blog/partners-dealers-agents', '/blog'],
  ['/blog/can-performance-and-rewards-digitization-transform-financial-services', '/blog'],
  ['/blog/the-promise-of-ai-for-sales-performance-management', '/blog'],
  ['/blog/using-your-rewards-platform-to-do-good', '/blog'],
  ['/blog/private-equity-firm-dedicated-compensation-solution', '/blog'],
  ['/blog/the-vision-you-sell-to-your-customers-and-the-reality-of-what-they-get', '/blog'],
  ['/blog/the-roi-of-automated-sales-compensation', '/blog'],
  ['/blog/the-missing-piece-in-hr-suites', '/blog'],
  [
    '/blog/beqom-announces-the-appointment-of-new-us-based-executive-director-and-board-member',
    '/blog',
  ],
  ['/blog/optimizing-compensation-spending-with-a-remote-workforce', '/blog'],
  ['/blog/continuous-total-rewards-meets-continuous-performance-management', '/blog'],
  ['/blog/total-rewards-cnh-industrial', '/blog'],
  ['/blog/we-surveyed-1600-enterprise-employees-about-the-gender-pay-gap', '/blog'],
  ['/blog/looking-for-a-robust-and-agile-spm-platform', '/blog'],
  ['/blog/deferred-compensation-european-banks', '/blog'],
  [
    '/blog/the-distributed-team-dilemma-how-to-effectively-manage-and-compensate-remote-sales-teams',
    '/blog',
  ],
  ['/blog/sales-commission-plans-clear-channel', '/blog'],
  ['/blog/first-uk-customer-event', '/blog'],
  ['/blog/capgemini-selects-beqom-to-manage-its-worldwide-compensation', '/blog'],
  ['/blog/new-work-deserves-new-pay', '/blog'],
  ['/blog/pay-transparency-is-here-and-we-couldnt-be-happier', '/blog'],
  ['/blog/sales-incentive-compensation-mercedes-belgium-luxembourg', '/blog'],
  ['/blog/giving-hope-to-orphans-of-aids', '/blog'],
  ['/blog/what-does-it-take-to-ensure-pay-equity', '/blog'],
  ['/blog/a-new-tool-to-calculate-the-roi-of-digital-compensation-management', '/blog'],
  ['/blog/finext-partnership', '/blog'],
  ['/blog/customer-centric-spirit', '/blog'],
  ['/blog/positioning-reward-for-the-future', '/blog'],
  ['/blog/optimize-your-compensation-spending-in-a-downturn', '/blog'],
  ['/blog/womens-equality-fair-pay-and-covid-19', '/blog'],
  ['/blog/three-ways-people-analytics-can-fix-the-biggest-compensation-problems', '/blog'],
  ['/blog/hr-processes-allied-irish-banks', '/blog'],
  ['/blog/e-rewards-conference-london', '/blog'],
  ['/blog/compensation-large-companies-ireland', '/blog'],
  ['/blog/opensymmetry-partnership', '/blog'],
  ['/blog/unleash-amsterdam-beqom-takeaways', '/blog'],
  ['/blog/uk-gender-pay-gap-report', '/blog'],
  ['/blog/simplifying-compensation-processes-for-remote-work', '/blog'],
  [
    '/blog/life-is-bigger-than-software-supporting-a-better-future-for-homeless-children-and-their-mothers',
    '/blog',
  ],
  ['/blog/sales-performance-management-strategies-preparing-for-success-in-the-new-year', '/blog'],
  ['/blog/5-questions-to-prepare-systems-for-the-never-normal-workplace', '/blog'],
  ['/blog/before-you-integrate-machine-learning-into-your-spm-read-this', '/blog'],
  [
    '/blog/beqom-solidifies-its-leadership-in-financial-services-by-signing-another-global-bank',
    '/blog',
  ],
  ['/blog/our-people-on-glassdoor', '/blog'],
  ['/blog/beqom-most-read-blogs-of-2020', '/blog'],
  ['/blog/8-signs-your-compensation-system-is-holding-your-business-back', '/blog'],
  ['/blog/future-proof-compensation-processes-for-regulatory-and-compliance-requirements', '/blog'],
  ['/blog/pay-scales-and-perks-in-the-hybrid-workplace', '/blog'],
  ['/blog/turn-on-a-dime-how-to-model-plan-and-execute-new-rewards-strategies', '/blog'],
  ['/blog/sales-performance-management-is-now-mainstream-according-to-gartner', '/blog'],
  ['/blog/compensation-challenges-in-the-energy-and-resources-industry', '/blog'],
  ['/blog/security-privacy-data-protection', '/blog'],
  ['/blog/doing-more-with-less-digital-efficiency-in-managing-rewards', '/blog'],
  ['/blog/fixed-and-variable-compensation-total-france', '/blog'],
  ['/blog/hr-people-analytics', '/blog'],
  [
    '/blog/beqom-scales-sales-incentives-plans-for-large-enterprises-powered-by-microsoft-azure',
    '/blog',
  ],
  ['/blog/the-core-value-of-solid-compensation-reporting', '/blog'],
  ['/blog/3-tips-for-competitive-hiring', '/blog'],
  ['/blog/2015', '/blog'],
  ['/blog/events-and-offices-are-back', '/blog'],
  ['/blog/how-effective-performance-and-rewards-management-can-stop-quiet-quitting', '/blog'],
  ['/blog/the-future-of-executive-compensation-beqom-takeaways', '/blog'],
  ['/blog/does-your-total-rewards-strategy-support-your-employer-brand', '/blog'],
  ['/blog/latest-deloitte-market-landscape-guide-advises-on-new-compensation-challenges', '/blog'],
  ['/blog/e-reward-annual-conference-in-london', '/blog'],
  ['/blog/rethinking-pay-in-the-hybrid-workplace', '/blog'],
  [
    '/blog/this-international-womens-day-americans-most-concerned-with-eliminating-gender-pay-gap',
    '/blog',
  ],
  ['/blog/reflections-on-talent-summit-2023', '/blog'],
  ['/blog/the-secret-to-setting-goals-that-work', '/blog'],
  ['/blog/using-analytics-to-impact-sales-performance', '/blog'],
  [
    '/blog/americans-most-concerned-with-companies-prioritizing-equal-pay-during-covid-19-pandemic',
    '/blog',
  ],
  [
    '/blog/does-your-compensation-technology-support-diversity-equity-inclusion-and-belonging',
    '/blog',
  ],
  ['/blog/millennials-gen-z-workers-want-to-know-what-their-ceo-makes', '/blog'],
  [
    '/blog/financial-sector-compensation-gender-pay-gap-diversity-and-regulatory-requirements',
    '/blog',
  ],
  ['/blog/empowering-female-sales-leaders', '/blog'],
  ['/blog/using-ai-to-break-the-compensation-equation', '/blog'],
  ['/blog/integrated-performance-reward-platform-bearingpoint', '/blog'],
  ['/blog/the-value-added-benefits-of-compensation-reporting', '/blog'],
  ['/blog/search', '/blog'],
  ['/blog/keeping-talent-how-to-retain-talent-by-using-the-right-hr-technology', '/blog'],
  ['/blog/making-data-driven-pay-decisions-in-inflationary-times', '/blog'],
  ['/blog/now-is-the-time-a-pay-equity-playbook', '/blog'],
  ['/blog/performance-management-process-retail', '/blog'],
  ['/blog/compensation-administration-capability-self-assessment', '/blog'],
  ['/blog/the-business-case-for-automating-compensation', '/blog'],
  ['/blog/sales-incentive-compensation-hr-compensation', '/blog'],
  ['/blog/capable-compensation-solution', '/blog'],
  ['/blog/umanis-spain-partnership-extension', '/blog'],
  ['/blog/how-to-show-your-employees-a-little-love', '/blog'],
  ['/blog/looking-at-the-big-picture', '/blog'],
  ['/blog/simplify-it-with-the-missing-compensation-link', '/blog'],
  ['/blog/using-ai-eliminate-wage-gaps', '/blog'],
  ['/blog/the-challenges-of-using-ai-for-sales-performance-management', '/blog'],
  ['/blog/why-should-performance-and-compensation-matter-to-large-enterprises', '/blog'],
  ['/blog/the-sales-automation-revolution', '/blog'],
  ['/blog/gleanster-research-gleansight-benchmark-report', '/blog'],
  ['/blog/how-much-pay-transparency-do-we-need', '/blog'],
  ['/blog/never-waste-a-crisis-adapting-compensation-systems-to-remote-work', '/blog'],
  [
    '/blog/need-to-change-course-add-these-features-to-your-compensation-management-toolkit',
    '/blog',
  ],
  ['/blog/what-is-compensation-management-part-1-two-different-worlds', '/blog'],
  ['/blog/the-reason-behind-the-beqom-brand-refresh', '/blog'],
  ['/blog/making-agile-total-rewards-a-reality', '/blog'],
  ['/blog/sales-performance-management-in-2018', '/blog'],
  ['/blog/bringing-transparency-to-womens-pay', '/blog'],
  ['/blog/life-is-bigger-than-software', '/blog'],
  ['/blog/stryker-go-live', '/blog'],
  ['/blog/successfully-managing-asc-606/ifrs-15-compliance-in-sales-compensation', '/blog'],
  ['/blog/goal-set-and-match-navigating-goals-in-a-global-enterprise', '/blog'],
  ['/blog/attracting-and-motivating-gen-z-talent', '/blog'],
  ['/blog/can-your-hr-platform-handle-your-compensation-management', '/blog'],
  ['/blog/5-lessons-from-2022-for-total-rewards', '/blog'],
  ['/blog/best-project-of-the-year-award', '/blog'],
  ['/blog/the-future-is-now-talent-and-compensation-strategies-that-drive-success', '/blog'],
  ['/blog/world-at-work-total-rewards', '/blog'],
  ['/blog/technology-sales-incentive-programs', '/blog'],
  ['/blog/compliance-hr-compensation', '/blog'],
  ['/blog/retail-turns-to-ai-to-curb-employee-turnover', '/blog'],
  ['/blog/annual-reward-processes-campari', '/blog'],
  ['/blog/dont-just-appreciate-hr-automate-hr', '/blog'],
  ['/blog/customer-event-and-community', '/blog'],
  ['/blog/can-equal-pay-for-women-become-a-reality', '/blog'],
  ['/blog/redefining-employee-rewards-the-3-top-challenges', '/blog'],
  [
    '/blog/beqom-selected-another-dax30-company-centralize-management-their-hr-compensation-processes',
    '/blog',
  ],
  ['/blog/redefining-employee-rewards-actions-for-success', '/blog'],
  ['/blog/are-you-being-paid-fairly', '/blog'],
  ['/blog/financial-services-master-your-compensation-cycle-with-cloud-based-technology', '/blog'],
  ['/blog/emdeon-sales-incentive-compensation', '/blog'],
  ['/blog/how-hr-digitization-will-transform-financial-services', '/blog'],
  ['/blog/performance-compensation-strategy-retail', '/blog'],
  ['/blog/the-path-to-happiness-how-beqom-can-make-your-people-happy', '/blog'],
  ['/blog/beqom-strengthens-its-presence-in-north-america', '/blog'],
  ['/blog/tackling-the-gender-pay-gap-at-financial-organizations', '/blog'],
  ['/blog/multi-tenant-compensation-solution', '/blog'],
  ['/blog/innovating-meet-modern-workforce-needs', '/blog'],
  ['/blog/how-to-realign-and-motivate-your-sales-teams-without-losing-talent', '/blog'],
  ['/blog/reward-innovation-dare-to-be-different', '/blog'],
  [
    '/blog/8-steps-to-sustainable-pay-equity-top-performance-part-2-align-processes-and-policies',
    '/blog',
  ],
  ['/blog/trending-topics-in-financial-services-compensation-management', '/blog'],
  ['/blog/highlights-from-leap-hr-financial-services', '/blog'],
  ['/blog/one-simple-human-error-can-cost-your-business-millions', '/blog'],
  ['/blog/compensation-trends-in-2022-one-to-one-compensation', '/blog'],
  ['/blog/total-compensation-solution-cnh-industrial', '/blog'],
  ['/blog/financial-services-teams-reveal-their-top-compensation-priorities-in-2020', '/blog'],
  ['/blog/3-inside-tips-for-planning-an-spm-analytics-data-strategy', '/blog'],
  [
    '/blog/beqom-announces-successful-deployment-its-solution-headquarters-chebanca-mediobanca-group',
    '/blog',
  ],
  ['/blog/keeping-talent-how-to-retain-talent-by-being-a-good-place-to-work', '/blog'],
  ['/blog/top-of-mind-for-financial-institutions', '/blog'],
  ['/blog/webinar-sales-performance-management-case-study-tim', '/blog'],
  ['/blog/eu-us-safe-harbor-framework', '/blog'],
  ['/blog/five-ways-data-can-help-you-drive-sales-performance', '/blog'],
  ['/blog/5-predictions-for-compensation-in-2022-whats-the-next-normal', '/blog'],
  ['/blog/3-pro-tips-for-winning-the-talent-wars', '/blog'],
  ['/blog/randstad-germany-go-live-with-beqom-total-compensation', '/blog'],
  ['/blog/jfk-and-the-janitor', '/blog'],
  ['/blog/make-pay-transparency-your-2019-resolution', '/blog'],
  ['/blog/sales-performance-management-time-change', '/blog'],
  ['/blog/pay-transparency-paving-the-way-to-employee-happiness', '/blog'],
  ['/blog/the-opportunity-cost-of-inefficient-compensation-management', '/blog'],
  ['/blog/hr-ny-summit', '/blog'],
  ['/blog/recruiting-and-retaining-generation-z-the-perks-that-matter', '/blog'],
  ['/blog/retail-industry-redefining-the-employee-experience', '/blog'],
  ['/blog/demystifying-territory-management', '/blog'],
  ['/blog/how-sales-organizations-are-changing-to-survive-and-thrive', '/blog'],
  ['/blog/innovation-leadership-total-compensation', '/blog'],
  ['/blog/sales-incentive-management-system-sunrise', '/blog'],
  ['/blog/300-million-investment-into-beqom-a-major-milestone-for-us-and-our-customers', '/blog'],
  ['/blog/incentive-compensation-microsoft-corporation', '/blog'],
  ['/blog/hr-compensation-process-travis-perkins', '/blog'],
  ['/blog/top-shifts-in-total-compensation-and-sales-performance-management-2018', '/blog'],
  ['/blog/fooling-around-with-compensation-speak', '/blog'],
  ['/blog/how-to-adapt-your-total-rewards-to-fit-a-remote-workforce', '/blog'],
  ['/blog/e-rewards-compensation-software-conference', '/blog'],
  ['/blog/reba-innovation-day-beqom-takeaways', '/blog'],
  ['/blog/co-selling-with-microsoft', '/blog'],
  [
    '/blog/a-rewards-platform-can-support-environmental-social-and-governance-goals-really',
    '/blog',
  ],
  ['/blog/unleash-london', '/blog'],
  ['/blog/total-compensation-management-microsoft-azure-cloud-platform', '/blog'],
  ['/blog/rewards-and-talent-management', '/blog'],
  ['/blog/us-workers-would-rather-get-a-new-job-than-talk-to-managers-about-pay', '/blog'],
  ['/blog/rewards-strategy', '/blog'],
  ['/blog/why-is-compensation-management-so-challenging-in-financial-services', '/blog'],
  ['/blog/cnhi-bonus-management', '/blog'],
  ['/blog/compensation-software-implementation', '/blog'],
  ['/blog/low-sales-productivity-impeding-revenue-growth-survey-shows', '/blog'],
  ['/blog/managing-global-compensation-continuous-performance-in-financial-services', '/blog'],
  ['/blog/deferred-compensation', '/blog'],
  ['/blog/beqom-one-worlds-largest-compensation-focused-technology-teams', '/blog'],
  ['/blog/global-company', '/blog'],
  ['/blog/increase-salesforce-engagement-and-motivation-unified-approach-spm', '/blog'],
  ['/blog/making-employees-happy-the-search-for-meaning-and-balance', '/blog'],
  ['/blog/understanding-compensation-management-seeking-unity', '/blog'],
  ['/blog/2022-compensation-trends-leveraging-what-youve-got', '/blog'],
  ['/blog/4-sales-performance-management-tips-for-2022', '/blog'],
  ['/blog/this-is-beqom-why-we-run', '/blog'],
  ['/blog/sales-performance-management-strategies-streamline-plans-and-prepare-for-2019', '/blog'],
  ['/blog/sales-performance-management-solution-turkcell', '/blog'],
  ['/blog/how-to-prioritize-equal-pay-during-covid-19', '/blog'],
  ['/blog/compensation-trends-in-2022-pay-scales-and-transparency', '/blog'],
  ['/blog/can-ai-transform-sales-performance-management', '/blog'],
  ['/blog/spotlight-on-sales-compensation-beqom-takeaways', '/blog'],
  ['/blog/sales-incentives-and-commissions-management-sunrise-communications', '/blog'],
  ['/blog/collaborative-hris-monnoyeur-group', '/blog'],
  ['/blog/three-top-talent-must-haves-in-a-new-employer', '/blog'],
  ['/blog/three-ways-to-use-predictive-analytics-for-talent-management', '/blog'],
  ['/blog/profit-sharing-plans-faurecia', '/blog'],
  ['/blog/attracting-top-talent-time-evolution', '/blog'],
  ['/blog/the-strategic-value-of-compensation', '/blog'],
  ['/blog/compensation-strategy-oil-gas-industry', '/blog'],
  ['/blog/are-you-a-compensation-administrator-workaholic', '/blog'],
  ['/blog/intangent-sales-performance-management-large-enterprises', '/blog'],
  ['/blog/unleash-the-value-of-end-to-end-compensation-automation-for-financial-services', '/blog'],
  [
    '/blog/managing-the-risk-of-unethical-incentive-plans-in-the-financial-services-industry',
    '/blog',
  ],
  ['/blog/managing-global-compensation-shouldnt-be-so-complicated', '/blog'],
  // News
  [
    '/news/future-of-hr-digital-transformation-includes-total-compensation-management-using-beqom-says-elior-group',
    '/news',
  ],
  ['/news/beqoms-accelerated-growth-drives-us-expansion', '/news'],
  [
    '/news/beqom-study-uncovers-how-compensation-and-benefits-are-impacting-talent-shortages',
    '/news',
  ],
  ['/news/ventana-research-names-beqom-as-total-compensation-value-index-leader', '/news'],
  ['/news/beqom-debuts-as-core-challenger-on-fosway-9-grid-for-talent-people-success', '/news'],
  [
    '/news/beqom-recognized-by-independent-research-firm-in-report-on-sales-performance-management-solutions',
    '/news',
  ],
  [
    '/news/beqom-positioned-as-challenger-in-february-2020-gartner-magic-quadrant-for-sales-performance-management',
    '/news',
  ],
  ['/news/beqom-wins-the-svc-suisse-romande-2018-award', '/news'],
  [
    '/news/beqom-study-shows-us-uk-employees-aware-of-gender-wage-gaps-but-not-of-actions-taken-by-employers-or-governments-to-close-them',
    '/news',
  ],
  ['/news/beqom-opens-park-avenue-office-to-serve-growing-customer-base-in-ny-area', '/news'],
  [
    '/news/new-beqom-release-gives-managers-unprecedented-insight-and-control-of-individualized-compensation-planning',
    '/news',
  ],
  ['/news/beqom-ceo-fabio-ronga-wins-ey-entrepreneur-of-the-year-award', '/news'],
  [
    '/news/beqom-expands-reach-into-asia-pacific-with-leading-global-investment-services-provider',
    '/news',
  ],
  [
    '/news/latest-beqom-mobile-app-provides-flexibility-to-drive-workforce-behavior-in-changing-times',
    '/news',
  ],
  [
    '/news/beqom-named-a-challenger-in-the-gartner-magic-quadrant-for-sales-performance-management',
    '/news',
  ],
  [
    '/news/hera-adds-beqom-sales-compensation-management-solution-to-stay-competitive-in-oil-gas-industry',
    '/news',
  ],
  ['/news/beqom-announces-polarys-as-strategic-technical-partner', '/news'],
  [
    '/news/beqom-announces-the-appointment-of-franck-cohen-former-sap-president-to-its-board-of-directors',
    '/news',
  ],
  ['/news/microsoft-azure-customers-now-able-to-acquire-beqom-with-existing-azure-credit', '/news'],
  [
    '/news/golub-capital-adopts-beqom-compensation-management-platform-provides-growth-financing',
    '/news',
  ],
  ['/news/groupama-group-uses-beqom-to-motivate-insurance-sales-network', '/news'],
  ['/news/beqom-study-shows-pandemic-has-eroded-workers-pay-transparency-pay-equity', '/news'],
  ['/news/adecco-selects-beqom-to-manage-employee-compensation', '/news'],
  ['/news/gartner-hype-cycle-for-human-capital-management-technology-2019', '/news'],
  ['/news/beqom-appoints-york-hutton-to-head-north-american-services', '/news'],
  [
    '/news/automotive-leader-faurecia-tells-how-it-manages-incentive-compensation-for-4500-executives-worldwide',
    '/news',
  ],
  [
    '/news/beqom-advances-compensation-management-solution-to-empower-hr-managers-and-employees',
    '/news',
  ],
  ['/news/energy-industry-leader-selects-beqom-to-manage-global-executive-compensation', '/news'],
  ['/news/ar%C3%A7elik-adopts-beqoms-sales-performance-management-solution', '/news'],
  ['/news/beqom-announces-ggs-it-consulting-as-strategic-technical-partner', '/news'],
  [
    '/news/localtapiola-adopts-beqom-sales-performance-management-to-adapt-to-fast-changing-markets',
    '/news',
  ],
  [
    '/news/beqom-release-improves-on-best-in-class-compensation-analytics-adds-compliance-and-audit-features-to-rewards-processes',
    '/news',
  ],
  ['/news/food-beverage-industry-turns-to-beqom-for-global-compensation-management', '/news'],
  ['/news/beqom-appoints-katherine-porras-to-head-customer-success', '/news'],
  ['/news/beqom-value-index-leader-for-sales-performance-management', '/news'],
  ['/news/beqom-announces-pvp-consulting-as-strategic-technical-partner', '/news'],
  ['/news/understanding-the-compensation-management-solution-market', '/news'],
  [
    '/news/mclagan-and-beqom-align-to-provide-mortgage-banks-with-breakthrough-compensation-management-solutions',
    '/news',
  ],
  [
    '/news/beqom-listed-as-a-sample-vendor-in-gartners-hype-cycle-for-human-capital-management-technology-2018',
    '/news',
  ],
  [
    '/news/beqom-announces-latest-enhancements-to-its-compensation-management-capabilities',
    '/news',
  ],
  ['/news/beqom-announces-solution-for-equal-pay-analysis-and-compliance', '/news'],
  [
    '/news/global-logistics-leader-transforms-compensation-and-benefits-processes-with-beqoms-total-compensation-solution',
    '/news',
  ],
  ['/news/beqom-named-a-top-compensation-management-software-solution', '/news'],
  ['/news/beqom-announces-new-module-to-support-staff-cost-and-headcount-planning', '/news'],
  ['/news/beqom-announces-beqom-rapide', '/news'],
  [
    '/news/beqom-appoints-vismay-gada-to-lead-strategy-and-development-of-financial-services-division',
    '/news',
  ],
  ['/news/come-together-building-a-global-company', '/news'],
  ['/news/leading-investment-advisory-selects-beqom-to-manage-global-compensation', '/news'],
  ['/news/latest-beqom-release-focused-on-collaboration-and-user-experience', '/news'],
  ['/news/lbanque-pictet-selects-beqom-to-manage-compensation', '/news'],
  ['/news/beqom-and-deloitte-consulting-team-up-to-deliver-hr-total-rewards-solution', '/news'],
  [
    '/news/syngenta-selects-beqom-for-flexible-powerful-long-term-incentive-plan-management',
    '/news',
  ],
  [
    '/news/beqoms-new-mobile-app-delivers-from-anywhere-access-to-critical-compensation-and-performance-data',
    '/news',
  ],
  ['/news/beqom-selected-by-jti-to-manage-global-compensation-for-40000-employees', '/news'],
  ['/news/adecco-group-launches-beqom-solution-while-fully-remote', '/news'],
  [
    '/news/beqom-recognized-as-a-representative-vendor-in-the-2021-gartner-market-guide-for-sales-performance-management',
    '/news',
  ],
  ['/news/global-oil-and-gas-giant-chooses-beqom-for-total-rewards-management', '/news'],
  ['/news/beqom-offers-first-calculator-of-gender-equal-pay-index-in-france', '/news'],
  ['/news/beqom-partners-with-nga-human-resources', '/news'],
  [
    '/news/beqom-recognized-as-one-of-europes-50-leading-super-scale-ups-for-second-consecutive-year',
    '/news',
  ],
  ['/news/beqom-provides-state-of-the-art-ui-ux-in-latest-product-release', '/news'],
  ['/news/beqom-study-shows-age-race-and-gender-affect-us-workers-compensation', '/news'],
  [
    '/news/beqom-listed-as-sample-vendor-in-gartners-hype-cycle-for-crm-sales-technology-2019',
    '/news',
  ],
  ['/news/beqom-achieves-iso-27001-certification', '/news'],
  [
    '/news/beqom-recognized-in-the-2021-gartner-innovation-insight-for-diversity-equity-and-inclusion-technologies',
    '/news',
  ],
  ['/news/beqom-expands-executive-management-team-with-the-addition-of-york-hutton', '/news'],
  ['/news/mike-deleonardis-joins-beqom-as-president-north-america', '/news'],
  ['/news/beqom-announces-beqom-flexfs-for-financial-services', '/news'],
  ['/news/beqom-recognized-as-a-sample-vendor-in-two-gartner-hype-cycle-reports', '/news'],
  ['/news/beqom-offers-first-plug-and-play-dynamic-total-rewards-statements', '/news'],
  ['/news/beqom-named-core-challenger-on-fosway-9-grid-for-talent-people-success-2021', '/news'],
  ['/news/beqom-named-to-constellation-shortlist-for-compensation-management', '/news'],
  [
    '/news/beqom-announces-selection-by-leading-alternative-investment-management-firm-to-manage-global-compensation',
    '/news',
  ],
  [
    '/news/beqom-leading-compensation-software-provider-names-vismay-gada-as-head-of-customer-success',
    '/news',
  ],
  ['/news/beqom-ranked-second-among-regions-top-employers', '/news'],
  ['/news/brad-perry-joins-beqom-as-cfo', '/news'],
  [
    '/fr-fr/news/communique-de-pressebeqom-announces-300-million-strategic-investment-from-sumeru',
    '/fr-fr/news/beqom-announces-300-million-strategic-investment-from-sumeru',
  ],
  // Resources.
  [
    '/data-driven-approach-to-sales-performance-management-eguide',
    '/resources/data-driven-approach-to-sales-performance-management-eguide',
  ],
  [
    '/white-paper-continuous-performance-meets-continuous-compensation',
    '/resources/white-paper-continuous-performance-meets-continuous-compensation',
  ],
  [
    '/video-how-randstad-attracts-talent-in-a-hyper-competitive-labor-market',
    '/resources/video-how-randstad-attracts-talent-in-a-hyper-competitive-labor-market',
  ],
  [
    '/video-how-faurecia-manages-incentive-compensation',
    '/resources/video-how-faurecia-manages-incentive-compensation',
  ],
  [
    '/post-pandemic-mantras-for-agile-total-rewards-webinar',
    '/resources/post-pandemic-mantras-for-agile-total-rewards-webinar',
  ],
  [
    '/the-3-pillars-of-a-high-performance-culture',
    '/resources/the-3-pillars-of-a-high-performance-culture',
  ],
  [
    '/new-landing-page-rewarding-the-moments-that-matter-eguide',
    '/resources/new-landing-page-rewarding-the-moments-that-matter-eguide',
  ],
  ['/agile-hr-survival-guide-webinar', '/resources/agile-hr-survival-guide-webinar'],
  [
    '/eguide-8-signs-your-compensation-system-is-holding-back-your-business',
    '/resources/eguide-8-signs-your-compensation-system-is-holding-back-your-business',
  ],
  [
    '/can-ai-transform-sales-performance-management-video',
    '/resources/can-ai-transform-sales-performance-management-video',
  ],
  [
    '/using-analytics-to-impact-sales-performance',
    '/resources/using-analytics-to-impact-sales-performance',
  ],
  [
    '/new-landing-page-the-future-of-performance-management-eguide',
    '/resources/new-landing-page-the-future-of-performance-management-eguide',
  ],
  [
    '/successfully-managing-asc606-ifrs-15-compliance-in-sales-compensation-download',
    '/resources/successfully-managing-asc606-ifrs-15-compliance-in-sales-compensation-download',
  ],
  [
    '/gender-pay-gap-what-your-employees-really-think-survey-report',
    '/resources/gender-pay-gap-what-your-employees-really-think-survey-report',
  ],
  [
    '/how-the-next-generation-of-workers-is-changing-culture-of-total-rewards-2019-eguide',
    '/resources/how-the-next-generation-of-workers-is-changing-culture-of-total-rewards-2019-eguide',
  ],
  ['/demystifying-territory-management', '/resources/demystifying-territory-management'],
  [
    '/its-time-to-take-your-people-analytics-seriously-download',
    '/resources/its-time-to-take-your-people-analytics-seriously-download',
  ],
  [
    '/agile-hr-how-to-adapt-motivate-through-periods-of-change-eguide',
    '/resources/agile-hr-how-to-adapt-motivate-through-periods-of-change-eguide',
  ],
  ['/pay-equity-playbook-eguide', '/resources/pay-equity-playbook-eguide'],
  ['/glossary', '/resources/glossary'],
  [
    '/a-guide-to-the-eu-pay-transparency-directive',
    '/resources/a-guide-to-the-eu-pay-transparency-directive',
  ],
  [
    '/2021-culture-compensation-report-employee-attitudes-employer-responses-effective-rewards-strategy',
    '/resources/2021-culture-compensation-report-employee-attitudes-employer-responses-effective-rewards-strategy',
  ],
  ['/leveling-the-paying-field', '/resources/leveling-the-paying-field'],
  [
    '/pay-equity-actions-that-matter-most-to-your-workforce-eguide',
    '/resources/pay-equity-actions-that-matter-most-to-your-workforce-eguide',
  ],
  [
    '/sma-total-compensation-practices-research-brief',
    '/resources/sma-total-compensation-practices-research-brief',
  ],
  [
    '/video-pay-equity-playbook-will-pay-off-for-your-business',
    '/resources/video-pay-equity-playbook-will-pay-off-for-your-business',
  ],
  [
    '/gender-pay-gap-what-your-employees-really-think-survey-report-1',
    '/resources/gender-pay-gap-what-your-employees-really-think-survey-report-1',
  ],
  [
    '/2022-compensation-culture-report',
    '/gender-pay-gap-what-your-employees-really-think-survey-report-1',
  ],
  ['/resources/manufacturing-retail-case-study', '/resources'],
  ['/hr-tech-2023', '/events'],
  ['/lowes-success-story', '/success-stories/lowes-success-story'],
  ['/payequity', '/products/pay-equity-by-payanalytics'],
  ['/artificial-intelligence-driven-compensation', '/products/pay-management'],
  ['/company/our-story/', '/about'],
  ['/contact/office-locations', '/contact-us'],
  ['/glossary-old', '/resources/glossary'],
  ['/platform', '/products/platform'],
  ['/integrations-new', '/products/platform'],
  ['/adecco-customer-success-story', '/success-stories/adecco-customer-success-story'],
  ['/ai-customer-use-case-cpg', '/success-stories/ai-customer-use-case-cpg'],
  ['/company/partners/page/1', '/about'],
  ['/sara-2023', '/events'],
  ['/integrations', '/products/platform'],
  ['/pe-SOW-Prjt/02/24', '/pe-sow-prjt'],
  ['/pe-sow-prjt/02/24', '/pe-sow-prjt'],
  ['/pe-SOW-Onb/02/24', '/pe-sow-onb'],
  ['/pe-sow-onb/02/24', '/pe-sow-onb'],
  ['/resources/deloitte-compensation-challenges-and-partnering-with-beqom', '/resources'],
  ['/why-beqom', '/about'],
  ['/leadershipsummit-sept2024-0-0', '/events/compensation-leadership-summit-2024'],
  [
    '/total-compensation-management/performance-management-and-calibration',
    '/total-compensation-management',
  ],
  ['/total-compensation-management/salary-management', '/total-compensation-management'],
  [
    '/sales-performance-management/incentive-compensation-management',
    '/sales-performance-management',
  ],
  ['/company/our-story', '/about'],
  ['/company', '/about'],
  ['/de/trust-center/cloud-sicherheit-und-datenschutz', '/trust-center'],
  ['/resources/local-requirements-usa', '/resources/local-requirements/usa'],
  ['/resources/local-requirements-sweden', '/resources/local-requirements/sweden'],
  ['/fr-fr/resources/local-requirements-france', '/fr-fr/resources/local-requirements/france'],
  ['/de-de/about-us', '/de-de/about'],
  ['/fr-fr/about-us', '/fr-fr/about'],
] as RedirectUrlPair[]
